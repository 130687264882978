import { Component } from 'react';

import { DayEvents } from '../Calendar';

import { circle_exclamation, schedule } from 'src/images';
import './style.scss';

const DAYS_IN_A_WEEK = 7;
const DAYS_IN_A_WORK_WEEK = 5;

interface Props {
  events: Record<number, Record<number, DayEvents>>;
  hasEvents: boolean;
  isError: boolean;
  isPortrait: boolean;
  now: Date;
  nowDateString: string;
  nowTimeString: string;
}

export default class CalendarWorkWeek extends Component<Props> {
  dates: Date[] = [];
  displayHeaders: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
  yesterday: Date = new Date();

  constructor(props: Props) {
    super(props);
    this.setDisplayDates();
    this.setYesterday();
  }

  render() {
    const { events, hasEvents, isError, isPortrait, now } = this.props;
    return (
      <div className="CalendarWorkWeek">
        <div className={`mainContainer ${isPortrait ? 'isPortrait' : ''}`}>
          {this.renderHeader()}
          {isError ? this.renderErrorDisplay() : (
            <div className={`nonHeader ${isPortrait ? 'isPortrait' : ''}`}>
              <div className="subtitle">
                What's on for the week
              </div>
              <div className="line" />
                {hasEvents ? (
                  <div className={`tableContainer ${isPortrait ? 'isPortrait' : ''}`}>
                    {!isPortrait && <div className="spacing"/>}
                    <div className={`table ${isPortrait ? 'isPortrait' : ''}`}>
                      {this.dates.map((date, index) => {
                        const isLastDay = index === 4;
                        const isToday = date.getDate() === now.getDate();
                        const isYesterday = date.getDate() === this.yesterday.getDate();
                        const shouldShowBorder = !(isToday || isYesterday || isLastDay);
                        return (
                          <div className={`day ${isPortrait ? 'isPortrait' : ''} ${isToday ? 'today' : ''} ${shouldShowBorder ? 'showBorder' : ''}`} key={index}>
                            <div className="dayContent">
                              <div className={`dateHeader ${isPortrait ? 'isPortrait' : ''}`}>
                                <div className={`dayOfWeek ${isPortrait ? 'isPortrait' : ''}`}>{date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase()}</div>
                                <div>{date.getDate()}</div>
                              </div>
                              <div className={`eventsContainer ${isPortrait ? 'isPortrait' : ''}`}>
                                {events[date.getMonth()] && events[date.getMonth()][date.getDate()] && events[date.getMonth()][date.getDate()].events.length > 0 && (
                                  this.renderEventsContainer(date)
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : this.renderNoEventsDisplay()}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderErrorDisplay() {
    const { isPortrait } = this.props;
    return (
      <div className={`errorContainer ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="error">
          <img alt="calendar not found" className="errorImage" src={circle_exclamation}/>
          <div className="errorMessage">Calendar not found! Please sync again.</div>
        </div>
      </div>
    );
  }

  renderEventsContainer(date: Date) {
    const { events, isPortrait } = this.props;
    return (
      <>
        {events[date.getMonth()][date.getDate()].events.map((event, index) =>
          <div className={`event ${event.happeningNow ? 'now' : ''} ${isPortrait ? 'isPortrait' : ''}`} key={index}>
            <div className="eventTitle">{event.title}</div>
            <div className="eventTime">{event.displayTime}</div>
          </div>
        )}
      </>
    );
  }

  renderHeader() {
    const { isPortrait, now, nowDateString, nowTimeString } = this.props;
    return (
      <div className={`header ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="headerContentWrapper">
          <div className="day">
            {now.toLocaleDateString('en-US', { weekday: 'long' })}
          </div>
          <div className="date">
            {nowDateString}
          </div>
          <div className="time">
            {nowTimeString}
          </div>
        </div>
      </div>
    );
  }

  renderNoEventsDisplay() {
    const { isPortrait } = this.props;
    return (
      <div className={`noEventsContainer ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="noEvents">
          <img alt="calendar not found" className="noEventsImage" src={schedule}/>
          <div className="noEventsMessage">You don't have any events this week.</div>
        </div>
      </div>
    );
  }

  setDisplayDates() {
    // Set dateToPush to be the Monday of the current week, or the coming Monday if today is a weekend.
    const dateToPush = new Date();
    const isWeekend = [0, 6].includes(dateToPush.getDay());
    const mondayDate = dateToPush.getDate() - ((dateToPush.getDay() + 6) % 7);
    dateToPush.setDate(isWeekend ? mondayDate + DAYS_IN_A_WEEK : mondayDate);

    for (let i = 0; i < DAYS_IN_A_WORK_WEEK; i++) {
      this.dates.push(new Date(dateToPush.getTime()));
      dateToPush.setDate(dateToPush.getDate() + 1);
    }
  }

  setYesterday() {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
  }
}
