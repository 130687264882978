import React, { Component } from 'react';

import { DayEvents } from '../Calendar';

import { circle_exclamation, schedule } from 'src/images';
import './style.scss';

const DAYS_IN_A_WEEK = 7;

interface Props {
  checkOverflowAndResize: (element: HTMLDivElement | null) => void;
  events: Record<number, Record<number, DayEvents>>;
  hasEvents: boolean;
  isError: boolean;
  isPortrait: boolean;
  now: Date;
  nowDateString: string;
  nowTimeString: string;
  startDay: string;
}

export default class CalendarMonth extends Component<Props> {
  calendarGrid: Date[][] = [];
  displayDateStart: Date = new Date();
  displayDateEnd: Date = new Date();
  displayHeaders: string[] = [];

  constructor(props: Props) {
    super(props);
    this.setDisplayHeaders();
    this.setDisplayDates();
    this.setCalendarGrid();
  }

  render() {
    const { hasEvents, isError, isPortrait, now } = this.props;
    const numRows = this.calendarGrid.length;
    return (
      <div className={`CalendarMonth ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="mainContainer">
          {isPortrait ? this.renderHeaderPortrait() : this.renderHeaderLandscape()}
          {isPortrait && !isError && (
            <div className="portraitSubtitle">
               What's on for the month
            </div>
          )}
          {isError ? this.renderErrorDisplay() : (
            <>
              <div className={`line ${isPortrait ? 'isPortrait' : ''}`}/>
              {hasEvents ? (
                <div className={`tableContainer ${isPortrait ? 'isPortrait' : ''}`}>
                  <table className={`table ${isPortrait ? 'isPortrait' : ''}`}>
                    <tbody className="tableBody">
                      <tr className="tableHeader" key="header">
                        {this.displayHeaders.map(displayDay =>
                          <th className={`tableHeaderCell ${isPortrait ? 'isPortrait' : ''}`} key={displayDay}>
                            {displayDay}
                          </th>
                        )}
                      </tr>
                      {this.calendarGrid.map((calendarRow, rowIndex) =>
                        <tr className={`tableRow ${isPortrait ? 'isPortrait' : numRows === 4 ? 'fourRows' : numRows === 5 ? 'fiveRows' : ''}`} key={rowIndex}>
                          {calendarRow.map((calendarDate: Date) => {
                            const isInMonth = calendarDate.getMonth() === now.getMonth();
                            const isToday = isInMonth && calendarDate.getDate() === now.getDate();
                            return (
                              <td className={`tableRowCell ${isToday ? 'today' : ''}  ${isInMonth ? '' : 'faded'} ${isPortrait ? 'isPortrait' : ''}`} key={calendarDate.getDate()}>
                                {this.renderMonthViewCell(calendarDate, isInMonth, isToday)}
                              </td>
                            );
                          })}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : this.renderNoEventsDisplay()}
            </>
          )}
        </div>
      </div>
    );
  }

  renderErrorDisplay() {
    const { isPortrait } = this.props;
    return (
      <div className={`errorContainer ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="error">
          <img alt="calendar not found" className="errorImage" src={circle_exclamation}/>
          <div className="errorMessage">Calendar not found! Please sync again.</div>
        </div>
      </div>
    );
  }

  renderHeaderLandscape() {
    const { checkOverflowAndResize, isError, nowDateString, nowTimeString } = this.props;
    return (
      <div className="header">
        <div className="time" ref={(div) => checkOverflowAndResize(div)}>
          {nowTimeString}
        </div>
        <div className="date">
          {nowDateString}
        </div>
        {!isError && (
          <div className="subtitle">
            What's on for the month
          </div>
        )}
      </div>
    );
  }

  renderHeaderPortrait() {
    const { nowDateString, nowTimeString } = this.props;
    return (
      <div className="headerPortrait">
        <div className="headerPortraitContentWrapper">
          <div className="date">
            {nowDateString}
          </div>
          <div className="time">
            {nowTimeString}
          </div>
        </div>
      </div>
    );
  }

  renderMonthViewCell(date: Date, isInMonth: boolean, isToday: boolean) {
    const { events, isPortrait } = this.props;
    const numRows = this.calendarGrid.length;
    const rowClass = numRows === 4 ? 'fourRows' : numRows === 5 ? 'fiveRows' : '';
    let cellEvents: DayEvents = {
      events: [],
      numHiddenEvents: 0
    };
    if (events[date.getMonth()] && events[date.getMonth()][date.getDate()] && events[date.getMonth()][date.getDate()].events.length > 0) {
      cellEvents = events[date.getMonth()][date.getDate()];
    }

    return (
      <div className={`calendarCell ${isInMonth ? '' : 'faded'}`}>
        <div className={`cellNumber ${rowClass}`}>
          {date.getDate()}
        </div>
        {cellEvents.events.map((event, index) =>
          <div className={`event ${isPortrait ? 'isPortrait' : rowClass} ${event.happeningNow ? 'now' : ''}`} key={index}>
            {event.title}
          </div>
        )}
        {cellEvents.numHiddenEvents > 0 && (
          <div className={`andMore ${rowClass}`}>
            {`And ${cellEvents.numHiddenEvents} more`}
          </div>
        )}
      </div>
    );
  }

  renderNoEventsDisplay() {
    const { isPortrait } = this.props;
    return (
      <div className={`noEventsContainer ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="noEvents">
          <img alt="calendar not found" className="noEventsImage" src={schedule}/>
          <div className="noEventsMessage">You don't have any events this month.</div>
        </div>
      </div>
    );
  }

  setCalendarGrid() {
    const numDaysToDisplay = (this.displayDateEnd.getTime() - this.displayDateStart.getTime()) / (24 * 60 * 60 * 1000);
    let numRows = 4;
    if (numDaysToDisplay > 35) {
      numRows = 6;
    } else if (numDaysToDisplay > 28) {
      numRows = 5;
    }

    this.calendarGrid = Array(numRows).fill(null).map(() => Array(DAYS_IN_A_WEEK).fill(null).map(() => new Date()));
    const iterationDate = new Date(this.displayDateStart.getTime());
    for (let i = 0; i < numRows; i++) {
      for (let j = 0; j < DAYS_IN_A_WEEK; j++) {
        this.calendarGrid[i][j] = new Date(iterationDate.getTime());
        iterationDate.setDate(iterationDate.getDate() + 1);
      }
    }
  }

  setDisplayDates() {
    const { startDay } = this.props;
    this.displayDateStart.setDate(1);
    this.displayDateStart.setDate(startDay === 'sunday' ? 1 - this.displayDateStart.getDay() : 2 - this.displayDateStart.getDay());
    this.displayDateEnd.setMonth(this.displayDateEnd.getMonth() + 1);
    this.displayDateEnd.setDate(0);
    if ((startDay === 'sunday' && this.displayDateEnd.getDay() !== 6) || (startDay === 'monday' && this.displayDateEnd.getDay() !== 0)) {
      if (startDay === 'sunday') {
        this.displayDateEnd.setDate(this.displayDateEnd.getDate() - this.displayDateEnd.getDay() + 6);
      } else {
        this.displayDateEnd.setDate(this.displayDateEnd.getDate() - this.displayDateEnd.getDay() + 7);
      }
    }
  }

  setDisplayHeaders() {
    const { startDay } = this.props;
    if (startDay === 'monday') {
      this.displayHeaders = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    } else {
      this.displayHeaders = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    }
  }
}
