import { Component } from 'react';

import Clock from '@viviedu/react-clock';

import SevenSegmentDigit from 'src/components/SevenSegmentDigit';
import { AddListener, RemoveSocketCallback } from 'src/components/Socket';
import formatSeconds from 'src/utils/formatSeconds';

import './style.scss';

interface Lap {
  diff: number;
  id: number;
  overall: number;
}

interface Props {
  isPortrait: boolean;
  onAddSocketListener: AddListener;
}

interface State {
  laps: Lap[];
  now: number;
  overallTime: number;
  paused: boolean;
  startTime: number | null;
  masterStartTime: number | null;
}

interface TimeData {
  time: number;
}

function toTimeData(data: Record<string, unknown>): TimeData | null {
  return (typeof data?.time === 'number') ? { time: data.time } : null;
}

const framesPerSecond = 30;

export default class Stopwatch extends Component<Props, State> {
  clockBaseTime: number;
  removeSocketCallbacks: RemoveSocketCallback[] = [];
  updateInterval: number | null = null;

  get displayedSeconds() {
    return this.state.overallTime / 1000 + this.millisecondsElapsedSinceLastStart / 1000;
  }

  get millisecondsElapsedSinceLastStart() {
    const { now, startTime } = this.state;
    if (startTime === null) {
      return 0;
    }

    return now - startTime;
  }

  constructor(props: Props) {
    super(props);

    const clockBaseTime = new Date();
    clockBaseTime.setHours(12, 0, 0, 0);
    this.clockBaseTime = clockBaseTime.getTime();

    this.state = {
      laps: [],
      now: performance.now(),
      overallTime: 0,
      paused: true,
      startTime: null,
      masterStartTime: null
    };
  }

  masterDisplayedSeconds(masterNow: number) {
    return this.state.overallTime / 1000 + this.masterMillisecondsElapsedSinceLastStart(masterNow) / 1000;
  }

  masterMillisecondsElapsedSinceLastStart(masterNow: number) {
    const { masterStartTime } = this.state;
    if (masterStartTime === null) {
      return 0;
    }

    return masterNow - masterStartTime;
  }

  componentDidMount() {
    this.removeSocketCallbacks.push(
      this.props.onAddSocketListener('lap', (data) => this.handleLap(toTimeData(data))),
      this.props.onAddSocketListener('pause', (data) => this.handlePause(toTimeData(data))),
      this.props.onAddSocketListener('play', (data) => {
        this.handlePlay(toTimeData(data));

        if (!this.updateInterval) {
          this.updateInterval = window.setInterval(this.update.bind(this), Math.floor(1000 / framesPerSecond));
          this.update();
        }
      }),
      this.props.onAddSocketListener('reset', (data) => this.handleReset(toTimeData(data)))
    );
  }

  componentWillUnmount() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }

    this.removeSocketCallbacks.forEach((callback) => {
      callback();
    });
  }

  handleLap(data: TimeData | null) {
    const maxLaps = this.props.isPortrait ? 15 : 10;
    const laps = [...this.state.laps];
    const lastLap = laps[laps.length - 1];
    // If provided (eg. slave box), display master lap times instead
    const masterLapTime = data?.time ?? null;
    const overall = masterLapTime ? this.masterDisplayedSeconds(masterLapTime) : this.displayedSeconds;
    laps.push({
      diff: lastLap ? overall - lastLap.overall : overall,
      id: lastLap ? lastLap.id + 1 : 1,
      overall
    });

    if (laps.length > maxLaps) {
      laps.shift();
    }

    this.setState({
      laps
    });
  }

  handlePause(data: TimeData | null) {
    const { masterStartTime, overallTime, paused, startTime } = this.state;
    if (paused || startTime === null) {
      return;
    }

    const masterPauseTime = data?.time;
    // synchronize with master if master start+pause timestamps available; now = startTime + (masterPauseTime - masterStartTime)
    if (masterPauseTime && masterStartTime) {
      const masterPauseDiff = this.masterMillisecondsElapsedSinceLastStart(masterPauseTime);
      const newNow = startTime + masterPauseDiff;
      this.setState({
        masterStartTime: masterPauseTime,
        now: newNow,
        overallTime: overallTime + masterPauseDiff,
        paused: true,
        startTime: newNow
      });
      return;
    }

    this.setState({
      overallTime: this.state.overallTime + this.millisecondsElapsedSinceLastStart,
      paused: true,
      startTime: performance.now()
    });
  }

  handlePlay(data: TimeData | null) {
    if (!this.state.paused) {
      return;
    }

    const masterPlayTime = data?.time ?? null;
    this.setState({
      masterStartTime: masterPlayTime,
      paused: false,
      startTime: performance.now()
    });
  }

  handleReset(data: TimeData | null) {
    const masterResetTime = data?.time ?? null;
    const now = performance.now();
    this.setState({
      laps: [],
      masterStartTime: masterResetTime,
      now,
      overallTime: 0,
      paused: true,
      startTime: now
    });
  }

  render() {
    const [hours, minutes, seconds, milliseconds] = formatSeconds(this.displayedSeconds);
    const clockOffset = this.state.overallTime + this.millisecondsElapsedSinceLastStart;
    const { isPortrait } = this.props;

    return (
      <div className={`Stopwatch ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="time">
          <div className="innerWrapper">
            <div className="bigClock">
              <Clock
                hourHandWidth={5}
                minuteHandWidth={3}
                numbersMultiplier={5}
                preciseSecondHandAngle={true}
                renderHourHand={false}
                renderMinuteHand={false}
                renderNumbers={true}
                secondHandLength={70}
                secondHandWidth={2}
                size={750}
                value={new Date(this.clockBaseTime + clockOffset)}
              />
            </div>
            <div className="smallClocks">
              <div>
                <Clock
                  hourHandWidth={5}
                  minuteHandWidth={3}
                  numbersMultiplier={2}
                  preciseSecondHandAngle={true}
                  renderHourHand={false}
                  renderMinuteHand={false}
                  renderNumbers={true}
                  secondHandLength={50}
                  secondHandWidth={1}
                  size={150}
                  value={new Date(this.clockBaseTime + clockOffset / 3600)}
                />
                <div>Hours</div>
              </div>
              <div>
                <Clock
                  hourHandWidth={5}
                  minuteHandWidth={3}
                  numbersMultiplier={5}
                  preciseSecondHandAngle={true}
                  renderHourHand={false}
                  renderMinuteHand={false}
                  renderNumbers={true}
                  secondHandLength={50}
                  secondHandWidth={1}
                  size={150}
                  value={new Date(this.clockBaseTime + clockOffset / 60)}
                />
                <div>Minutes</div>
              </div>
            </div>
            <div className="digital">
              <div className="sevenSegmentDigits">
                {hours.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`hours-${index}`} size="small" value={value} />)}
                <SevenSegmentDigit size="small" value=":" />
                {minutes.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`minutes-${index}`} size="small" value={value} />)}
                <SevenSegmentDigit size="small" value=":" />
                {seconds.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`seconds-${index}`} size="small" value={value} />)}
              </div>
              <div className="milliseconds">
                {milliseconds}
              </div>
            </div>
          </div>
        </div>
        <div className="laps">
          <table>
            <thead>
              <tr>
                <th>Lap</th>
                <th>Lap Time</th>
                <th>Overall</th>
              </tr>
            </thead>
            <tbody>
              {this.state.laps.map(({ diff, id, overall }) => (
                <tr key={id}>
                  <th>
                    {id}
                  </th>
                  <th>
                    {this.renderLapTime(diff)}
                  </th>
                  <th>
                    {this.renderLapTime(overall)}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  // seconds -> hours:minutes:seconds.milliseconds
  renderLapTime(seconds: number) {
    const formattedLapTime = formatSeconds(seconds);
    return `${formattedLapTime.slice(0, -1).map((digit) => digit.toString().padStart(2, '0')).join(':')}.${formattedLapTime[3].toString().padStart(3, '0')}`;
  }

  update() {
    if (this.state.paused) {
      return;
    }

    this.setState({
      now: performance.now()
    });
  }
}
