import { Component } from 'react';
import { arrayP, booleanP, objectP, stringP, GetType } from 'type-proxy';

import '@formatjs/intl-locale/polyfill';

// must be done in this order
import '@formatjs/intl-displaynames/polyfill';
// tslint:disable-next-line:ordered-imports
import '@formatjs/intl-displaynames/locale-data/en';

import * as images from '../../images';
import { AddListener, RemoveSocketCallback } from '../Socket';
import './style.scss';

const ordinalRules = new Intl.PluralRules('en', {
  type: 'ordinal'
});

const ordinalSuffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
};

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const articleP = objectP({
  category: stringP,
  description: stringP,
  imageUrl: stringP,
  name: stringP
});

const newsDataP = objectP({
  articles: arrayP(articleP),
  retry: booleanP
});

interface Props {
  articleDuration: number;
  countryCode: string;
  isPortrait: boolean;
  onAddSocketListener: AddListener;
}

interface State {
  articles: Article[];
  currArticle: Article;
  currArticleImage: string;
  currArticleIndex: number;
}

interface Article extends GetType<typeof articleP> {}

export default class News extends Component<Props, State> {
  removeSocketCallbacks: RemoveSocketCallback[] = [];

  constructor(props: Props) {
    super(props);
    const { countryCode } = this.props;

    this.state = {
      articles: [],
      currArticle: {
        category: 'N/A',
        description: 'There is a connection issue or delay in the news feed. Please wait for the feed to refresh.',
        imageUrl: images.loading,
        name: 'No News to Display'
      },
      currArticleImage: images.loading,
      currArticleIndex: -1
    };

    const countryNameTemp = regionNames.of(countryCode);
    this.countryName = countryNameTemp ? countryNameTemp : '';
  }

  private countryName: string;
  private changeArticleTimeout: number | null = null;
  private seenTitles: Set<string> = new Set();

  async componentDidMount() {
    this.removeSocketCallbacks.push(
      this.props.onAddSocketListener('news_data', (data: unknown) => {
        const parsedData = newsDataP(data);
        if (parsedData.success) {
          this._clearChangeArticleTimeout();
          this._runOneArticleIteration(parsedData.value.articles, parsedData.value.retry);
        }
      })
    );
  }

  componentWillUnmount() {
    this._clearChangeArticleTimeout();
  }

  render() {
    if (this._isNotLoading()) {
      this.seenTitles.add(this.state.currArticle.name);
    }
    return this._renderArticle(this.state.currArticle);
  }

  private _capitaliseString(myString: string) {
    if (myString) {
      return myString.charAt(0).toUpperCase() + myString.slice(1);
    }
    return '';
  }

  // This method only updates the index and image of the next article. The rest of the article
  // (its category, title, description) only get updated after the image finishes loading.
  private _changeDisplayArticle(newArticles: Article[] | null) {
    const isUpdate = newArticles !== null && newArticles.length > 0;
    let currArticleIndex;
    if (isUpdate) {
      newArticles.forEach(article => {
        if (!article.imageUrl) {
          if (article.category) {
            article.imageUrl = this._getDefaultImage(article.category);
          } else {
            article.imageUrl = images.defaultNews;
          }
        }
      });

      currArticleIndex = newArticles.length - 1;

      // This ensures we don't see the same articles again for a while when we retrieve new data
      let foundSeenArticle = false;
      while (currArticleIndex >= 0 && !foundSeenArticle) {
        if (this.seenTitles.has(newArticles[currArticleIndex].name)) {
          foundSeenArticle = true;
        } else {
          currArticleIndex--;
        }
      }
      currArticleIndex = (currArticleIndex + 1) % newArticles.length;
    } else {
      currArticleIndex = (this.state.currArticleIndex + 1) % this.state.articles.length;
    }

    const articles = isUpdate ? newArticles : this.state.articles;
    const nextArticle = isUpdate ? newArticles[currArticleIndex] : this.state.articles[currArticleIndex];
    const imageChanged = nextArticle.imageUrl !== this.state.currArticle?.imageUrl;

    this.setState({
      articles: articles,
      currArticle: imageChanged ? this.state.currArticle : nextArticle,
      currArticleImage: nextArticle.imageUrl,
      currArticleIndex: currArticleIndex
    });
  }

  private _clearChangeArticleTimeout() {
    if (this.changeArticleTimeout) {
      clearTimeout(this.changeArticleTimeout);
    }
    this.changeArticleTimeout = null;
  }

  private _getDateString() {
    const now = new Date();
    const day = now.toLocaleDateString('en-US', { weekday: 'long' });
    const date = `${now.getDate()}${ordinalSuffixes[ordinalRules.select(now.getDate()) as keyof typeof ordinalSuffixes]}`;
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const year = now.getFullYear();
    return `${day}, ${date} ${month}, ${year}`;
  }

  private _getDefaultImage(category: string) {
    switch (category) {
      case 'australia': return images.australia;
      case 'business': return images.business;
      case 'entertainment': return images.entertainment;
      case 'health': return images.health;
      case 'science': return images.science;
      case 'sports': return images.sports;
      case 'technology': return images.technology;
      case 'uk': return images.uk;
      case 'us': return images.us;
      case 'world': return images.world;
      default: return images.defaultNews;
    }
  }

  private _handleCategoryDisplay(category: string) {
    if (category) {
      if (['uk', 'us'].includes(category)) {
        return this.countryName;
      }
      return this._capitaliseString(category);
    }
    return this.countryName;
  }

  private _isNotLoading() {
    return this.state.currArticleImage !== images.loading;
  }

  private _renderArticle(article: Article) {
    const { isPortrait } = this.props;
    return (
      <div className="News">
        <div className={`imageContainer ${isPortrait ? 'isPortrait' : ''}`}>
          <img
            alt="news"
            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              if (this._isNotLoading()) {
                event.currentTarget.src = article.category ? this._getDefaultImage(article.category) : images.defaultNews;
                this.setState({ currArticle: this.state.articles[this.state.currArticleIndex] });
              }
            }}
            onLoad={() => {
              if (this._isNotLoading()) {
                this.setState({ currArticle: this.state.articles[this.state.currArticleIndex] });
              }
            }}
            src={this.state.currArticleImage}
          />
        </div>
        <div className={`mainContainer ${isPortrait ? 'isPortrait' : ''} ${article.category}`}>
          <div className={`descriptionContainer ${isPortrait ? 'isPortrait' : ''}`}>
            <div className={`newsCategory ${article.category || this.countryName ? '' : 'none'} ${isPortrait ? 'isPortrait' : ''}`}>
              {this._capitaliseString(this._handleCategoryDisplay(article.category))}
            </div>
            <div className={`date ${isPortrait ? 'isPortrait' : ''}`}>
              {this._getDateString()}
            </div>
            <div className={`title ${isPortrait ? 'isPortrait' : ''}`}>
              {this._capitaliseString(article.name)}
            </div>
            <div className={`description ${isPortrait ? 'isPortrait' : ''}`}>
              {this._capitaliseString(article.description)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private async _runOneArticleIteration(newArticles: Article[] | null, shouldRetry: boolean) {
    const { articleDuration } = this.props;
    this._changeDisplayArticle(newArticles);
    if (!shouldRetry) {
      this.seenTitles = new Set();
      this.changeArticleTimeout = window.setTimeout(this._runOneArticleIteration.bind(this, null, false), articleDuration);
    }
  }
}
