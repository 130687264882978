import { Component } from 'react';

import { CalendarEvent } from '../Calendar';

import { circle_exclamation, schedule } from 'src/images';
import './style.scss';

interface Props {
  events: CalendarEvent[];
  isError: boolean;
  isPortrait: boolean;
  now: Date;
  nowDateString: string;
  nowTimeString: string;
}

export default class CalendarDay extends Component<Props> {
  render() {
    const { events, isError, isPortrait, now, nowDateString, nowTimeString } = this.props;
    const displayTwoColumns = isPortrait ? events.length > 7 : events.length > 5;
    return (
      <div className="CalendarDay">
        <div className={`mainContainer ${isPortrait ? 'isPortrait' : ''}`}>
          <div className={`headerContainer ${isPortrait ? 'isPortrait' : ''}`}>
            <div className={`header ${isPortrait ? 'isPortrait' : ''}`}>
              <div className="day">
                {now.toLocaleDateString('en-US', { weekday: 'long' })}
              </div>
              <div className="date">
                {nowDateString}
              </div>
              <div className="time">
                {nowTimeString}
              </div>
            </div>
          </div>
          <div className={`eventsSection ${isPortrait ? 'isPortrait' : ''}`}>
            {isError ? this.renderErrorDisplay() : (
              <div className="noErrorSection">
                <div className="subtitle">
                  What's on for today
                </div>
                <div className="line" />
                <div className={`eventsContainer ${isPortrait ? 'isPortrait' : ''} ${displayTwoColumns ? 'twoColumns' : ''}`}>
                  {events.length === 0 ? this.renderNoEventsDisplay() : (
                    events.map((event, index) =>
                      <div className={`event ${event.happeningNow ? 'now' : ''} ${displayTwoColumns ? '' : 'long'}`} key={index}>
                        <div className="eventTitle">{event.title}</div>
                        <div className="eventTime">{event.displayTime}</div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderErrorDisplay() {
    const { isPortrait } = this.props;
    return (
      <div className={`errorContainer ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="error">
          <img alt="calendar not found" className="errorImage" src={circle_exclamation}/>
          <div className="errorMessage">Calendar not found! Please sync again.</div>
        </div>
      </div>
    );
  }

  renderNoEventsDisplay() {
    return (
      <div className="noEvents">
        <img alt="calendar not found" className="noEventsImage" src={schedule}/>
        <div className="noEventsMessage">You don't have any events today.</div>
      </div>
    );
  }
}
