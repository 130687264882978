import SevenSegmentDigit, { Size } from 'src/components/SevenSegmentDigit';

import './style.scss';

interface Props {
  now: Date;
  size: Size;
}

export default function ClockDigital({ now, size }: Props) {
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  const displayedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
  const isAm = hours <= 11;

  return (
    <div className={`ClockDigital ${size}`}>
      <div className="meridiem">
        <div className={isAm ? '' : 'inactive'}>
          am
        </div>
        <div className={isAm ? 'inactive' : ''}>
          pm
        </div>
      </div>
      <div className="time">
        {displayedHours.toString().split('').map((value, index) => <SevenSegmentDigit key={`hours-${index}`} size={size} value={value} />)}
        <SevenSegmentDigit size={size} value=":" />
        {minutes.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`minutes-${index}`} size={size} value={value} />)}
        <SevenSegmentDigit size={size} value=":" />
        {seconds.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`seconds-${index}`} size={size} value={value} />)}
      </div>
    </div>
  );
}
