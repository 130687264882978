import { createRef, Component } from 'react';

import SevenSegmentDigit from 'src/components/SevenSegmentDigit';
import { AddListener, RemoveSocketCallback, SendEvent } from 'src/components/Socket';
import formatSeconds from 'src/utils/formatSeconds';

import './style.scss';

interface Props {
  alertSeconds: string;
  audioAlert: boolean;
  isPortrait: boolean;
  onAddSocketListener: AddListener;
  onSendSocketEvent: SendEvent;
  repeats: string;
  seconds: string;
}

interface State {
  now: number;
  overallTime: number;
  paused: boolean;
  repeatsRemaining: number;
  startTime: number;
}

export default class Timer extends Component<Props, State> {
  audioRef = createRef<HTMLAudioElement>();
  removeSocketCallbacks: RemoveSocketCallback[] = [];
  stopValue: number;
  updateInterval: number | null = null;

  get audio() {
    return this.audioRef.current;
  }

  constructor(props: Props) {
    super(props);

    const { alertSeconds, repeats, seconds } = this.props;
    this.stopValue = -parseInt(alertSeconds, 10);

    const now = performance.now();

    this.state = {
      now,
      overallTime: parseInt(seconds, 10),
      paused: true,
      repeatsRemaining: parseInt(repeats, 10),
      startTime: now
    };
  }

  componentDidMount() {
    this.removeSocketCallbacks.push(
      this.props.onAddSocketListener('pause', () => this.handlePause()),
      this.props.onAddSocketListener('play', () => {
        this.handlePlay();

        if (!this.updateInterval) {
          this.updateInterval = window.setInterval(this.update.bind(this), 100);
          this.update();
        }
      }),
      this.props.onAddSocketListener('set_volume', (data) => {
        const audio = this.audioRef.current;
        if (audio) {
          audio.volume = data.volume as number;
        }
      })
    );
  }

  componentWillUnmount() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }

    this.removeSocketCallbacks.forEach((callback) => {
      callback();
    });
  }

  getDisplayedSeconds(now: number) {
    return Math.max(this.stopValue, Math.ceil(this.state.overallTime - this.getSecondsElapsedSinceLastStart(now)));
  }

  getEnding(now: number) {
    return this.state.repeatsRemaining === 0 && this.getDisplayedSeconds(now) < 1;
  }

  getSecondsElapsedSinceLastStart(now: number) {
    return (now - this.state.startTime) / 1000;
  }

  handlePause() {
    if (this.state.paused || this.getEnding(this.state.now)) {
      return;
    }

    if (this.getDisplayedSeconds(this.state.now) < 1) {
      this.reset(this.state.now, true);
    } else {
      this.setState({
        overallTime: this.state.overallTime - this.getSecondsElapsedSinceLastStart(this.state.now),
        paused: true,
        startTime: this.state.now
      });
    }
  }

  handlePlay() {
    if (!this.state.paused || this.getEnding(this.state.now)) {
      return;
    }

    const now = performance.now();

    this.setState({
      now,
      paused: false,
      startTime: now
    });
  }

  render() {
    const displayedSeconds = this.getDisplayedSeconds(this.state.now);
    const [hours, minutes, seconds] = displayedSeconds >= 0 ? formatSeconds(displayedSeconds) : [0, 0, -displayedSeconds];
    const size = this.props.isPortrait ? 'medium' : 'large';

    return (
      <div className="Timer">
        <div className="innerWrapper">
          {displayedSeconds < 0 && <SevenSegmentDigit size={size} value="-" />}
          {hours.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`hours-${index}`} size={size} value={value} />)}
          <SevenSegmentDigit size={size} value=":" />
          {minutes.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`minutes-${index}`} size={size} value={value} />)}
          <SevenSegmentDigit size={size} value=":" />
          {seconds.toString().padStart(2, '0').split('').map((value, index) => <SevenSegmentDigit key={`seconds-${index}`} size={size} value={value} />)}
          {displayedSeconds < 0 && <SevenSegmentDigit size={size} value=" " />}
        </div>
        <div className={`darkBackground ${displayedSeconds >= 0 || displayedSeconds % 2 === 0 ? 'hide' : ''}`} />
        <audio ref={this.audioRef} src="/timer-alert.mp3" />
      </div>
    );
  }

  reset(now: number, paused: boolean) {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    this.setState({
      now,
      overallTime: parseInt(this.props.seconds, 10),
      paused,
      repeatsRemaining: this.state.repeatsRemaining - 1,
      startTime: now
    });
  }

  update() {
    if (this.state.paused) {
      return;
    }

    const now = performance.now();
    const displayedSeconds = this.getDisplayedSeconds(now);

    if (displayedSeconds < 1 && this.props.audioAlert && this.audio?.paused) {
      this.audio?.play();
    }

    const getStopped = (nowValue: number) => this.state.overallTime - this.getSecondsElapsedSinceLastStart(nowValue) <= this.stopValue;

    if (!getStopped(this.state.now) && getStopped(now)) {
      if (this.state.repeatsRemaining > 0) {
        this.reset(now, false);
        return;
      }

      this.audio?.pause();
      this.props.onSendSocketEvent('end');
    }

    if (!this.getEnding(this.state.now) && this.getEnding(now)) {
      this.props.onSendSocketEvent('ending');
    }

    this.setState({
      now
    });
  }
}
