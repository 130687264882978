import React, { Component } from 'react';

import { DayEvents } from '../Calendar';

import { circle_exclamation, schedule } from 'src/images';
import './style.scss';

const DAYS_IN_A_WEEK = 7;

interface Props {
  checkOverflowAndResize: (element: HTMLDivElement | null) => void;
  events: Record<number, Record<number, DayEvents>>;
  hasEvents: boolean;
  isError: boolean;
  isPortrait: boolean;
  now: Date;
  nowDateString: string;
  nowTimeString: string;
  startDay: string;
}

export default class CalendarWeek extends Component<Props> {
  dates: Date[] = [];
  displayHeaders: string[] = [];
  yesterday: Date = new Date();

  constructor(props: Props) {
    super(props);
    this.setDisplayHeaders();
    this.setDisplayDates();
    this.setYesterday();
  }

  render() {
    const { events, hasEvents, isError, isPortrait, now } = this.props;
    return (
      <div className="CalendarWeek">
        <div className={`mainContainer ${isPortrait ? 'isPortrait' : ''}`}>
          {isPortrait ? this.renderHeaderPortrait() : this.renderHeaderLandscape()}
          {isPortrait && !isError && (
            <div className="portraitSubtitle">
               What's on for the week
            </div>
          )}
          {isError ? this.renderErrorDisplay() : (
            <>
              <div className="line" />
                {hasEvents ? (
                  <div className={`tableContainer ${isPortrait ? 'isPortrait' : ''}`}>
                    {!isPortrait && <div className="spacing"/>}
                    <div className={`table ${isPortrait ? 'isPortrait' : ''}`}>
                      {this.dates.map((date, index) => {
                        const isLastDay = index === 6;
                        const isToday = date.getDate() === now.getDate();
                        const isWeekend = [0, 6].includes(date.getDay());
                        const isYesterday = date.getDate() === this.yesterday.getDate();
                        const shouldShowBorder = !(isToday || isYesterday || isLastDay);
                        return (
                          <div className={`day ${isPortrait ? 'isPortrait' : ''} ${isToday ? 'today' : ''} ${shouldShowBorder ? 'showBorder' : ''}`} key={index}>
                            <div className={`dayContent ${isWeekend ? 'faded' : ''}`}>
                              <div className={`dateHeader ${isPortrait ? 'isPortrait' : ''}`}>
                                <div className={`dayOfWeek ${isPortrait ? 'isPortrait' : ''}`}>{date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase()}</div>
                                <div>{date.getDate()}</div>
                              </div>
                              <div className={`eventsContainer ${isPortrait ? 'isPortrait' : ''}`}>
                                {events[date.getMonth()] && events[date.getMonth()][date.getDate()] && events[date.getMonth()][date.getDate()].events.length > 0 && (
                                  this.renderEventsContainer(date)
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : this.renderNoEventsDisplay()}
            </>
          )}
        </div>
      </div>
    );
  }

  renderErrorDisplay() {
    const { isPortrait } = this.props;
    return (
      <div className={`errorContainer ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="error">
          <img alt="calendar not found" className="errorImage" src={circle_exclamation}/>
          <div className="errorMessage">Calendar not found! Please sync again.</div>
        </div>
      </div>
    );
  }

  renderEventsContainer(date: Date) {
    const { events, isPortrait } = this.props;
    return (
      <>
        {events[date.getMonth()][date.getDate()].events.map((event, index) =>
          <div className={`event ${event.happeningNow ? 'now' : ''} ${isPortrait ? 'isPortrait' : ''}`} key={index}>
            <div className="eventTitle">{event.title}</div>
            <div className="eventTime">{event.displayTime}</div>
          </div>
        )}
      </>
    );
  }

  renderHeaderLandscape() {
    const { checkOverflowAndResize, isError, nowDateString, nowTimeString } = this.props;
    return (
      <div className="header">
        <div className="time" ref={(div) => checkOverflowAndResize(div)}>
          {nowTimeString}
        </div>
        <div className="date">
          {nowDateString}
        </div>
        {!isError && (
          <div className="subtitle">
            What's on for the week
          </div>
        )}
      </div>
    );
  }

  renderHeaderPortrait() {
    const { nowDateString, nowTimeString } = this.props;
    return (
      <div className="headerPortrait">
        <div className="headerPortraitContentWrapper">
          <div className="date">
            {nowDateString}
          </div>
          <div className="time">
            {nowTimeString}
          </div>
        </div>
      </div>
    );
  }

  renderNoEventsDisplay() {
    const { isPortrait } = this.props;
    return (
      <div className={`noEventsContainer ${isPortrait ? 'isPortrait' : ''}`}>
        <div className="noEvents">
          <img alt="calendar not found" className="noEventsImage" src={schedule}/>
          <div className="noEventsMessage">You don't have any events this week.</div>
        </div>
      </div>
    );
  }

  setDisplayDates() {
    const { startDay } = this.props;
    const dateToPush = new Date();
    // Set dateToPush to be the first day of the week
    dateToPush.setDate(startDay === 'sunday' ? dateToPush.getDate() - dateToPush.getDay() : dateToPush.getDate() - ((dateToPush.getDay() + 6) % DAYS_IN_A_WEEK));
    for (let i = 0; i < DAYS_IN_A_WEEK; i++) {
      this.dates.push(new Date(dateToPush.getTime()));
      dateToPush.setDate(dateToPush.getDate() + 1);
    }
  }

  setDisplayHeaders() {
    const { startDay } = this.props;
    if (startDay === 'monday') {
      this.displayHeaders = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    } else {
      this.displayHeaders = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    }
  }

  setYesterday() {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
  }
}
