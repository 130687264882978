import { format, utcToZonedTime } from 'date-fns-tz';
import { Component } from 'react';
import { arrayP, numberP, objectP, or2P, orP, stringP, strLiteralP, undefinedP, GetType } from 'type-proxy';

import mockData from 'src/assets/mock/weather_data.json';
import { AddListener, RemoveSocketCallback, SendEvent } from 'src/components/Socket';
import { broken_clouds_day_filled, broken_clouds_day_outlined, clear_sky_day_filled,
         clear_sky_day_outlined, clear_sky_night_filled, cloudy_day_filled, cloudy_day_outlined,
         cloudy_night_filled, cloudy_night_outlined, mist_day_filled, mist_day_outlined, rain_day_filled,
         rain_day_outlined, scattered_clouds_day_filled, scattered_clouds_day_outlined, snow_day_filled,
         snow_day_outlined, thunderstorm_day_filled, thunderstorm_day_outlined } from 'src/images';
import './style.scss';

export type MeasurementUnit = 'metric' | 'imperial';

export type ForecastType = 'daily' | 'hourly';

/* Notes about API:
  1. These declarations do not cover all the attributes from the response.
  2. All timestamps provided are in seconds and need to be converted to milliseconds before use.
  3. Unit for temperature and wind speed change according to the unit provided in the query
  4. See documentation for further details: https://openweathermap.org/api/one-call-3.
*/
const commonDescriptionP = orP(
  [
    strLiteralP('Clear'),
    strLiteralP('Clouds'),
    strLiteralP('Drizzle'),
    strLiteralP('Rain'),
    strLiteralP('Snow'),
    strLiteralP('Thunderstorm')
  ]
);

const atmosphereDescriptionP = orP(
  [
    strLiteralP('Ash'),
    strLiteralP('Dust'),
    strLiteralP('Fog'),
    strLiteralP('Mist'),
    strLiteralP('Haze'),
    strLiteralP('Sand'),
    strLiteralP('Smoke'),
    strLiteralP('Squall'),
    strLiteralP('Tornado')
  ]
);

const weatherDescriptionP = objectP({
  main: or2P(commonDescriptionP, atmosphereDescriptionP),
  description: stringP
});

const tempDataP = objectP({
  day: numberP,
  min: numberP,
  max: numberP
});

const weatherDataP = objectP({
  dt: numberP,
  sunrise: or2P(undefinedP, numberP),
  sunset: or2P(undefinedP, numberP),
  temp: or2P(numberP, tempDataP),
  feels_like: or2P(numberP, objectP({})),
  pressure: numberP,
  humidity: numberP,
  uvi: numberP,
  clouds: numberP,
  wind_speed: numberP,
  wind_deg: numberP,
  weather: arrayP(weatherDescriptionP)
});

const weatherResponseP = objectP({
  timezone: stringP,
  current: weatherDataP,
  hourly: arrayP(weatherDataP),
  daily: arrayP(weatherDataP)
});

interface Props {
  city: string;
  forecastType: ForecastType;
  isPortrait: boolean;
  onAddSocketListener: AddListener;
  onSendSocketEvent: SendEvent;
  serverPort: string;
  unit: MeasurementUnit;
}

interface State {
  currentWeatherProperties: WeatherData | null;
  dailyWeatherProperties: WeatherData[];
  hourlyWeatherProperties: WeatherData[];
  message: string;
  lastUpdatedAt: number | null;
  timezone: string;
}

interface TempData extends GetType<typeof tempDataP> {}
interface WeatherData extends GetType<typeof weatherDataP> {}

export default class Weather extends Component<Props, State> {
  removeSocketCallbacks: RemoveSocketCallback[] = [];

  readonly DEFAULT_TIMEOUT = 30 * 1000;
  readonly RETRY_TIMEOUT = 8 * 1000;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentWeatherProperties: null,
      dailyWeatherProperties: [],
      hourlyWeatherProperties: [],
      lastUpdatedAt: null,
      message: '',
      timezone: ''
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      this.update(mockData);
      return;
    }

    this.removeSocketCallbacks.push(
      this.props.onAddSocketListener('weather_data', (data) => this.update(data))
    );
  }

  componentWillUnmount() {
    this.removeSocketCallbacks.forEach((callback) => {
      callback();
    });
  }

  update(data: unknown) {
    const parsedData = weatherResponseP(data);
    if (parsedData.success) {
      this.setState({
        timezone: parsedData.value.timezone,
        currentWeatherProperties: parsedData.value.current,
        hourlyWeatherProperties: parsedData.value.hourly,
        dailyWeatherProperties: parsedData.value.daily,
        lastUpdatedAt: this.now()
      });
    } else {
      this.setState({
        timezone: '',
        currentWeatherProperties: null,
        hourlyWeatherProperties: [],
        dailyWeatherProperties: [],
        lastUpdatedAt: this.now(),
        message: 'Weather data is not available at the moment.'
      });
      return;
    }
  }

  render() {
    const { forecastType, isPortrait } = this.props;
    const { currentWeatherProperties } = this.state;
    return (
      <div className="Weather">
        {this.renderLastUpdate()}
        {
          currentWeatherProperties
          ? this.renderWeather(forecastType, isPortrait)
          : this.renderMessage()
        }
      </div>
    );
  }

  renderLastUpdate() {
    const lastUpdatedAt = this.state.lastUpdatedAt;
    return (
      lastUpdatedAt
      ?
        <div className="lastUpdated">
          <div>
            {
              lastUpdatedAt + this.getMsUntilNextHour(lastUpdatedAt) < this.now()
                ? `Last updated: ${this.formatTime(lastUpdatedAt, 'LLL d hh:mma')}`
                : ''
            }
          </div>
        </div>
      : <></>
    );
  }

  renderMessage() {
    return (
      <div className="message">
        {this.state.message}
      </div>
    );
  }

  renderWeather(forecastType: string, isPortrait: boolean) {
    return (
      <div className={`weather ${isPortrait ? 'isPortrait' : ''}`}>
        {this.renderCurrentWeather()}
        {
          forecastType === 'daily'
          ? this.renderDailyForecast()
          : this.renderHourlyForecast()
        }
      </div>
    );
  }

  renderCurrentWeather() {
    const { currentWeatherProperties } = this.state;
    const { city, unit } = this.props;
    if (!currentWeatherProperties) {
      return <></>;
    }
    return (
      <div className="currentWeather">
        <div className="primaryInfoContainer">
          <span className="city">{city}</span>
          <span className="currentTemp">
              {Math.round(currentWeatherProperties.temp as number)}
              &deg;
              {unit === 'metric' ? 'C' : 'F'}
          </span>
          <div className="dateContainer">
            <div className="today">Today</div><span className="date">{this.formatTime(this.now(), 'EEEE, MMMM d')}</span>
          </div>
        </div>

      <div className="weatherInfoContainer">
        {
          this.renderWeatherIconByDescription(
            true,
            currentWeatherProperties.weather[0].main,
            currentWeatherProperties.weather[0].description,
            currentWeatherProperties.dt,
            currentWeatherProperties.sunrise,
            currentWeatherProperties.sunset
            )
        }
        {this.renderDescription(currentWeatherProperties.weather[0].main)}
      </div>

      {this.renderOptionalData()}

      </div>
    );
  }

  renderDailyForecast() {
    const { isPortrait } = this.props;
    const { dailyWeatherProperties } = this.state;
    return (
      <div className="forecast">
        {
          // get the weather for the next 4 days
          dailyWeatherProperties.slice(1, 5).map((dailyWeatherProperty, index) => {
            if (!dailyWeatherProperty) {
              return <></>;
            }
            return isPortrait
              ? this.renderDailyPortrait(dailyWeatherProperty, index)
              : this.renderDailyLandscape(dailyWeatherProperty, index);
          })
        }
      </div>
    );
  }

  renderDailyLandscape(dailyWeatherProperty: WeatherData, index: number) {
    return (
      <div className="dailyForecastContainer" key={dailyWeatherProperty.dt}>
        <span className="dayOfWeek">
          {index === 0
            ? 'Tomorrow'
            : this.formatTime(dailyWeatherProperty.dt * 1000, 'EEEE')
          }
        </span>
        {
          this.renderWeatherIconByDescription(
            false,
            dailyWeatherProperty.weather[0].main,
            dailyWeatherProperty.weather[0].description
          )
        }
        {this.renderDescription(dailyWeatherProperty.weather[0].main)}
        {this.renderMaxMinTemperature(dailyWeatherProperty.temp)}
      </div>
    );
  }

  renderDailyPortrait(dailyWeatherProperty: WeatherData, index: number) {
    return (
      <div className="dailyForecastContainer" key={dailyWeatherProperty.dt}>
        <div className="timeAndTemp">
          <span className="dayOfWeek">
            { index === 0
              ? 'Tmw'
              : this.formatTime(dailyWeatherProperty.dt * 1000, 'E')
            }
          </span>
          {this.renderMaxMinTemperature(dailyWeatherProperty.temp)}
        </div>
        <div className="iconAndDescription">
          {
            this.renderWeatherIconByDescription(
              false,
              dailyWeatherProperty.weather[0].main,
              dailyWeatherProperty.weather[0].description
            )
          }
          {this.renderDescription(dailyWeatherProperty.weather[0].main)}
        </div>
      </div>
    );
  }

  renderHourlyForecast() {
    const { isPortrait } = this.props;
    const { hourlyWeatherProperties } = this.state;
    return (
      <div className="forecast">
        {
          // get the weather for the next 6 hours
          hourlyWeatherProperties.slice(1, 7).map(hourlyWeatherProperty => {
            if (!hourlyWeatherProperty) {
              return <></>;
            }
            return isPortrait
              ? this.renderHourlyPortrait(hourlyWeatherProperty)
              : this.renderHourlyLandscape(hourlyWeatherProperty);
          })
        }
      </div>
    );
  }

  renderHourlyLandscape(hourlyWeatherProperty: WeatherData) {
    return (
    <div className="hourlyForecastContainer" key={hourlyWeatherProperty.dt}>
      <span className="time">{this.formatTime(hourlyWeatherProperty.dt * 1000, 'h aaa')}</span>
      {
        this.renderWeatherIconByDescription(
          false,
          hourlyWeatherProperty.weather[0].main,
          hourlyWeatherProperty.weather[0].description,
          hourlyWeatherProperty.dt,
          hourlyWeatherProperty.sunrise,
          hourlyWeatherProperty.sunset
          )
      }
      {this.renderDescription(hourlyWeatherProperty.weather[0].main)}
      <span className="temperature">{Math.round(hourlyWeatherProperty.temp as number)}&deg;</span>
    </div>
    );
  }

  renderHourlyPortrait(hourlyWeatherProperty: WeatherData) {
    return (
      <div className="hourlyForecastContainer" key={hourlyWeatherProperty.dt}>
        <div className="timeAndTemp">
          <div className="time">{this.formatTime(hourlyWeatherProperty.dt * 1000, 'h aaa')}</div>
          <div className="temperature">{Math.round(hourlyWeatherProperty.temp as number)}&deg;</div>
        </div>
        <div className="iconAndDescription">
        {
          this.renderWeatherIconByDescription(
            false,
            hourlyWeatherProperty.weather[0].main,
            hourlyWeatherProperty.weather[0].description,
            hourlyWeatherProperty.dt,
            hourlyWeatherProperty.sunrise,
            hourlyWeatherProperty.sunset
          )
        }
        {this.renderDescription(hourlyWeatherProperty.weather[0].main)}
        </div>
      </div>
    );
  }

  renderDescription(description: string) {
    switch (description) {
      case 'Clouds':
        description = 'Cloudy';
        break;
      case 'Rain':
        description = 'Rainy';
        break;
      case 'Snow':
        description = 'Snowy';
        break;
      default:
    }
    return (
      <span className="description">{description}</span>
    );
  }

  renderMaxMinTemperature(temp: TempData | number) {
    const tempData = tempDataP(temp);
    if (!tempData.success) {
      return <></>;
    }

    const max = tempData.value.max;
    const min = tempData.value.min;
    return (
      <div className="temperature">
        <span className="max">{Math.round(max)}&deg;</span>
        <span className="min">{Math.round(min)}&deg;</span>
      </div>
    );
  }

  renderOptionalData() {
    const { currentWeatherProperties } = this.state;
    const UVIndex = currentWeatherProperties?.uvi as number;
    const humidity = currentWeatherProperties?.humidity as number;
    const windSpeed = currentWeatherProperties?.wind_speed as number;
    const windDegree = currentWeatherProperties?.wind_deg as number;
    const feelsLike = Math.round(currentWeatherProperties?.feels_like as number);

    return (
      <div className="optional">
        {this.renderFeelsLike(feelsLike)}
        {this.renderUVIndex(UVIndex)}
        {this.renderWindSpeedAndDirection(windSpeed, windDegree, this.props.unit)}
        {this.renderHumidity(humidity)}
      </div>
    );
  }

  renderFeelsLike(feelsLike: number) {
    return (
      <div className="optionalInfo">
        <span>{`Feels like ${feelsLike}`}&deg;</span>
      </div>
    );
  }

  renderHumidity(humidity: number) {
    return (
      <div className="optionalInfo">
        <span>{`Humidity: ${humidity}%`}</span>
      </div>
    );
  }

  renderPressure(pressure: number) {
    return (
      <div className="optionalInfo">
        <span>{`Pressure: ${pressure}hPa`}</span>
      </div>
    );
  }

  renderSunriseSunset(isSunrise: boolean, timestamp: number) {
    const text = isSunrise ? 'Sunrises at: ' : 'Sunsets at: ';
    return (
      <div className="optionalInfo">
        <span>{text + this.formatTime(timestamp, 'p')}</span>
      </div>
    );
  }

  renderUVIndex(UVIndex: number) {
    if (!UVIndex || UVIndex < 0 || UVIndex > 16) {
      return <></>;
    }
    return (
      <div className="optionalInfo">
        <span>{`UV: ${UVIndex}`}</span>
      </div>
    );
  }

  renderWindSpeedAndDirection(windSpeed: number, windDegree: number, unit: MeasurementUnit) {
    if (windDegree < 0 || windDegree > 360) {
      return <></>;
    }
    return (
      <div className="optionalInfo">
        <span>
          {`Wind: ${windSpeed} ${unit === 'metric' ? 'm/s ' : 'mph '}`}
          {this.renderWindDirection(windDegree)}
        </span>
      </div>
    );
  }

  renderWindDirection(windDegree: number) {
    const caridnals: string[] = [ 'N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N' ];
    return caridnals[Math.round((windDegree % 360) / 45)];
  }

  renderWeatherIconByDescription(filled: boolean, main: string, description: string,
                                 timestamp?: number, sunrise?: number, sunset?: number) {
    let dayTime = true;
    if (timestamp && sunrise && sunset) {
      if (timestamp < sunrise || timestamp > sunset) {
        dayTime = false;
      }
    }
    if (filled) {
      if (atmosphereDescriptionP(main).success) {
        return <img src={mist_day_filled} alt="mist day filled"/>;
      }
      switch (main) {
        case 'Clear':
          return dayTime
            ?  <img src={clear_sky_day_filled} alt="clear sky day filled"/>
            :  <img src={clear_sky_night_filled} alt="clear sky night filled"/>;
        case 'Clouds':
          switch (description) {
            case 'broken clouds':
              return <img src={broken_clouds_day_filled} alt="broken clouds day filled"/>;
            case 'scattered clouds':
              return <img src={scattered_clouds_day_filled} alt="scattered clouds day filled"/>;
            default:
              return dayTime
                ? <img src={cloudy_day_filled} alt="cloudy day filled"/>
                : <img src={cloudy_night_filled} alt="cloudy night filled"/>;
          }
        case 'Drizzle':
          return <img src={rain_day_filled} alt="drizzle day filled"/>;
        case 'Rain':
          return <img src={rain_day_filled} alt="rainy day filled"/>;
        case 'Snow':
          return <img src={snow_day_filled} alt="snowy day filled"/>;
        case 'Thunderstorm':
          return <img src={thunderstorm_day_filled} alt="thunderstorm day filled"/>;
        default:
          return <img src={cloudy_day_filled} alt="default day filled"/>;
      }
    } else {
      if (atmosphereDescriptionP(main).success) {
        return <img src={mist_day_outlined} alt="mist day outlined"/>;
      }
      switch (main) {
        case 'Clear':
          return <img src={clear_sky_day_outlined} alt="clear sky day outlined"/>;
        case 'Clouds':
          switch (description) {
            case 'broken clouds':
              return <img src={broken_clouds_day_outlined} alt="broken clouds day filled"/>;
            case 'scattered clouds':
              return <img src={scattered_clouds_day_outlined} alt="scattered clouds day filled"/>;
            default:
              return dayTime
                ? <img src={cloudy_day_outlined} alt="cloudy day outlined"/>
                : <img src={cloudy_night_outlined} alt="cloudy night filled"/>;
          }
        case 'Drizzle':
          return <img src={rain_day_outlined} alt="drizzle day outlined"/>;
        case 'Rain':
          return <img src={rain_day_outlined} alt="rainy day outlined"/>;
        case 'Snow':
          return <img src={snow_day_outlined} alt="snowy day outlined"/>;
        case 'Thunderstorm':
          return <img src={thunderstorm_day_outlined} alt="thunderstorm day outlined"/>;
        default:
          return <img src={cloudy_day_outlined} alt="default day outlined"/>;
      }
    }
  }

  formatTime(timestamp: number, expression: string) {
    const { timezone } = this.state;
    const date = new Date(timestamp);
    if (timezone) {
      const localTime = utcToZonedTime(date, timezone);
      return format(localTime, expression);
    }
    return format(date, expression);
  }

  getMsUntilNextHour(timestamp: number) {
    const nextHourFromTime = new Date(timestamp);
    nextHourFromTime.setHours(nextHourFromTime.getHours() + 1, 0, 0, 0);
    return nextHourFromTime.getTime() - timestamp;
  }

  now() {
    const now = new Date();
    return now.getTime();
  }
}
