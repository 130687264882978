import { Component } from "react";

import "@viviedu/react-clock/dist/Clock.css";

import "intl-pluralrules";

import Calendar from "./components/Calendar";
import Clock, { Style } from "./components/Clock";
import News from "./components/News";
import Socket, { AddListener, SendEvent } from "./components/Socket";
import Stopwatch from "./components/Stopwatch";
import Timer from "./components/Timer";
import Weather, { ForecastType, MeasurementUnit } from "./components/Weather";
import Multizone from "./components/Multizone";

import "./App.scss";

class App extends Component {
  urlSearchParams = new URLSearchParams(window.location.search);

  componentDidMount() {
    if (devicePixelRatio !== 1) {
      // zoom is non-standard so doesn't exist in the style types
      (document.body.style as unknown as Record<string, string>).zoom = (
        window.innerWidth / 1920
      ).toFixed(2);
    }
  }

  getParam(name: string, def: string) {
    const value = this.urlSearchParams.get(name);
    return value ?? def;
  }

  render() {
    const drawSplash = this.getParam("draw_splash_bar", "") === "true";
    const isPortrait = this.getParam("is_portrait", "false") === "true";
    const serverPort = this.getParam("widget_server_port", "12808");
    const type = this.getParam("type", "calendar_google");
    const title = this.getParam("title", "");

    return (
      <Socket serverPort={serverPort}>
        {(addSocketListener, sendSocketEvent) => {
          return (
            <div className="App">
              {![
                "calendar_google",
                "calendar_outlook",
                "news",
                "weather",
              ].includes(type) && <div className="title">{title}</div>}
              <div className="widget">
                {this.renderWidget(
                  type,
                  isPortrait,
                  addSocketListener,
                  sendSocketEvent
                )}
              </div>
              {!["calendar_google", "calendar_outlook", "news"].includes(
                type
              ) && (
                <div
                  className={`splashBar ${isPortrait ? "isPortrait" : ""} ${
                    drawSplash ? "draw" : ""
                  }`}
                >
                  {drawSplash && "The splash bar will appear here 🤠"}
                </div>
              )}
            </div>
          );
        }}
      </Socket>
    );
  }

  renderWidget(
    type: string,
    isPortrait: boolean,
    addSocketListener: AddListener,
    sendSocketEvent: SendEvent
  ) {
    const alertSeconds = this.getParam("alert_seconds", "0");
    const articleSeconds = this.getParam("article_seconds", "10");
    const audioAlert = this.getParam("audio_alert", "false") === "true";
    const calendarView = this.getParam("calendar_view", "week");
    const city = this.getParam("city", "Melbourne");
    const countryCode = this.getParam("country", "AU");
    const forecastType = this.getParam("forecast_type", "hourly");
    const repeats = this.getParam("repeats", "0");
    const seconds = this.getParam("seconds", "100");
    const serverPort = this.getParam("widget_server_port", "12808");
    const startDay = this.getParam("start_day", "monday");
    const style = this.getParam("style", "hybrid") as Style;
    const unit = this.getParam("unit", "metric");

    switch (type) {
      case "calendar_google":
      case "calendar_outlook":
        return (
          <Calendar
            isPortrait={isPortrait}
            calendarView={calendarView}
            onAddSocketListener={addSocketListener}
            startDay={startDay}
          />
        );
      case "clock":
        return (
          <Clock
            isPortrait={isPortrait}
            style={Object.values(Style).includes(style) ? style : Style.HYBRID}
          />
        );
      case "news":
        return (
          <News
            articleDuration={parseInt(articleSeconds, 10) * 1000}
            countryCode={countryCode}
            isPortrait={isPortrait}
            onAddSocketListener={addSocketListener}
          />
        );
      case "stopwatch":
        return (
          <Multizone
            isPortrait={isPortrait}
            onAddSocketListener={addSocketListener}
          />
          // <Stopwatch
          //   isPortrait={isPortrait}
          //   onAddSocketListener={addSocketListener}
          // />
        );
      case "timer":
        return (
          <Timer
            alertSeconds={alertSeconds}
            audioAlert={audioAlert}
            isPortrait={isPortrait}
            onAddSocketListener={addSocketListener}
            onSendSocketEvent={sendSocketEvent}
            repeats={repeats}
            seconds={seconds}
          />
        );
      case "weather":
        return (
          <Weather
            city={city}
            forecastType={forecastType as ForecastType}
            isPortrait={isPortrait}
            onAddSocketListener={addSocketListener}
            onSendSocketEvent={sendSocketEvent}
            serverPort={serverPort}
            unit={unit as MeasurementUnit}
          />
        );
      case "multizone":
        return (
          <Multizone
            isPortrait={isPortrait}
            onAddSocketListener={addSocketListener}
          />
        );
      default:
        return <></>;
    }
  }
}

export default App;
