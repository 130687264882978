import { Component } from 'react';

import ClockAnalog from 'src/components/ClockAnalog';
import ClockDigital from 'src/components/ClockDigital';

import './style.scss';

export enum Style {
  ANALOG = 'analog',
  DIGITAL = 'digital',
  HYBRID = 'hybrid'
}

interface Props {
  isPortrait: boolean;
  style: Style;
}

interface State {
  now: Date;
}

const ordinalRules = new Intl.PluralRules('en', {
  type: 'ordinal'
});
const ordinalSuffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
};
export default class Clock extends Component<Props, State> {
  updateTimeout: number | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      now: new Date()
    };
  }

  componentDidMount() {
    this.update();
  }

  componentWillUnmount() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }
  }

  getMsUntilNextSecond(now: Date) {
    const nextSecondFromNow = new Date();
    nextSecondFromNow.setSeconds(nextSecondFromNow.getSeconds() + 1, 0);
    return nextSecondFromNow.getTime() - now.getTime();
  }

  render() {
    const { isPortrait, style } = this.props;
    const { now } = this.state;

    const day = now.toLocaleDateString('en-US', { weekday: 'long' });
    const date = `${now.getDate()}${ordinalSuffixes[ordinalRules.select(now.getDate()) as keyof typeof ordinalSuffixes]}`;
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const year = now.getFullYear();

    return (
      <div className="Clock">
        <div className="clock">
          {this.renderClock(now, style, isPortrait)}
        </div>
        <div className="date">
          {day} {date} {month} {year}
        </div>
      </div>
    );
  }

  renderClock(now: Date, style: Style, isPortrait: boolean) {
    if (style === 'digital') {
      return <ClockDigital now={now} size={isPortrait ? 'medium' : 'large'} />;
    } else if (style === 'analog') {
      return <ClockAnalog now={now} />;
    } else {
      return (
        <div className={`hybrid ${isPortrait ? 'isPortrait' : ''}`}>
          <div>
            <ClockAnalog now={now} />
          </div>
          <div>
            <ClockDigital now={now} size="medium" />
          </div>
        </div>
      );
    }
  }

  update() {
    const now = new Date();
    this.setState({ now });
    this.updateTimeout = window.setTimeout(this.update.bind(this), this.getMsUntilNextSecond(now));
  }
}
