import { Component } from 'react';
import { arrayP, objectP, stringP } from 'type-proxy';

import CalendarDay from 'src/components/CalendarDay';
import CalendarMonth from 'src/components/CalendarMonth';
import CalendarWeek from 'src/components/CalendarWeek';
import CalendarWorkWeek from 'src/components/CalendarWorkWeek';
import { AddListener, RemoveSocketCallback } from '../Socket';

const eventP = objectP({
  end_date: stringP,
  start_date: stringP,
  title: stringP
});

const eventsDataP = objectP({
  events: arrayP(eventP)
});

interface Props {
  calendarView: string;
  isPortrait: boolean;
  onAddSocketListener: AddListener;
  startDay: string;
}

interface State {
  events: Record<number, Record<number, CalendarEvent[]>>;
  eventsToDisplay: Record<number, Record<number, DayEvents>>;
  hasEvents: boolean;
  isError: boolean;
  now: Date;
}

export interface CalendarEvent {
  displayTime: string;
  endDate: Date;
  happeningNow: boolean;
  startDate: Date;
  title: string;
}

export interface DayEvents {
  events: CalendarEvent[];
  numHiddenEvents: number;
}

const ordinalRules = new Intl.PluralRules('en', {
  type: 'ordinal'
});
const ordinalSuffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
};

export default class Calendar extends Component<Props, State> {
  numEventsDisplayedPerDay: number = 2;
  removeSocketCallbacks: RemoveSocketCallback[] = [];
  updateTimeout: number | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      events: {},
      eventsToDisplay: {},
      hasEvents: false,
      isError: false,
      now: new Date()
    };
    this.setNumEventsDisplayedPerDay();
  }

  async componentDidMount() {
    const { now } = this.state;
    this.removeSocketCallbacks.push(
      this.props.onAddSocketListener('calendar_data', (data: unknown) => {
        const parsedData = eventsDataP(data);
        if (!parsedData.success) {
          this.setState({ isError: true });
        } else if (parsedData.value.events.length > 0) {
          const parsedEvents = parsedData.value.events.map(event => {
            const { end_date, start_date } = event;
            const eventEndDate = new Date(end_date);
            const eventStartDate = new Date(start_date);
            return {
              displayTime: this.getEventTimeRangeString(eventEndDate, eventStartDate),
              endDate: eventEndDate,
              happeningNow: eventStartDate.getTime() < now.getTime() && eventEndDate.getTime() > now.getTime(),
              startDate: eventStartDate,
              title: event.title
            };
          });
          this.processEvents(parsedEvents);
        }
      })
    );
    this.update();
  }

  componentWillUnmount() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }
  }

  getDateString() {
    const { calendarView } = this.props;
    const { now } = this.state;
    const date = ['day', 'workweek'].includes(calendarView) ? `${now.getDate()}${ordinalSuffixes[ordinalRules.select(now.getDate()) as keyof typeof ordinalSuffixes]} ` : '';
    const month = now.toLocaleDateString('en-US', { month: 'long' });
    const year = now.getFullYear();
    return `${date}${month} ${year}`;
  }

  getEventTimeRangeString(endDate: Date, startDate: Date) {
    const startsAtStartOfDay = startDate.getHours() === 0 && startDate.getMinutes() === 0;
    const endsAtEndOfDay = endDate.getHours() === 23 && endDate.getMinutes() === 59;
    if (startsAtStartOfDay && endsAtEndOfDay) {
      return 'All day';
    }
    if (endsAtEndOfDay) {
      return `Starts at ${this.getEventTimeString(startDate)}`;
    }
    if (startsAtStartOfDay) {
      return `Ends at ${this.getEventTimeString(endDate)}`;
    }
    return `${this.getEventTimeString(startDate)} to ${this.getEventTimeString(endDate)}`;
  }

  getEventTimeString(date: Date) {
    const hours = date.getHours();
    const displayHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    const displayMinutes = date.getMinutes() > 0 ? `.${String(date.getMinutes()).padStart(2, '0')}` : '';
    const isMorning = hours < 12;
    return `${displayHours}${displayMinutes} ${isMorning ? 'AM' : 'PM'}`;
  }

  getTimeString() {
    const { now } = this.state;
    const hours = now.getHours();
    const displayHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    const isMorning = hours < 12;
    return `${String(displayHours).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')} ${isMorning ? 'AM' : 'PM'}`;
  }

  getMsUntilNextMinute(now: Date) {
    const nextMinuteFromNow = new Date();
    nextMinuteFromNow.setMinutes(nextMinuteFromNow.getMinutes() + 1, 0);
    return nextMinuteFromNow.getTime() - now.getTime();
  }

  processEvents(parsedEvents: CalendarEvent[]) {
    const { now } = this.state;
    const cataloguedEvents: Record<number, Record<number, CalendarEvent[]>> = {};
    const cataloguedEventsToDisplay: Record<number, Record<number, DayEvents>> = {};
    for (const event of parsedEvents) {
      const eventMonth = event.startDate.getMonth();
      const eventDate = event.startDate.getDate();
      if (!cataloguedEvents[eventMonth]) {
        cataloguedEvents[eventMonth] = {};
        cataloguedEventsToDisplay[eventMonth] = {};
      }
      if (!cataloguedEvents[eventMonth][eventDate]) {
        cataloguedEvents[eventMonth][eventDate] = [];
      }
      cataloguedEvents[eventMonth][eventDate].push(event);
    }

    for (const month of Object.keys(cataloguedEvents).map(Number)) {
      for (const day of Object.keys(cataloguedEvents[month]).map(Number)) {
        const isToday = month === now.getMonth() && day === now.getDate();
        cataloguedEvents[month][day].sort(this.sortFunctionGenerator(now, isToday));
        let updatedEvents: CalendarEvent[];
        if (isToday) {
          updatedEvents = this.updateEventsToDisplay(cataloguedEvents[month][day]);
        } else {
          updatedEvents = cataloguedEvents[month][day].slice(0, this.numEventsDisplayedPerDay);
        }
        cataloguedEventsToDisplay[month][day] = {
          events: updatedEvents,
          numHiddenEvents: Math.max(0, cataloguedEvents[month][day].length - this.numEventsDisplayedPerDay)
        };
      }
    }
    this.setState({
      events: cataloguedEvents,
      eventsToDisplay: cataloguedEventsToDisplay,
      hasEvents: true
    });
  }

  render() {
    const { calendarView, isPortrait, startDay } = this.props;
    const { eventsToDisplay, hasEvents, isError, now } = this.state;
    if (calendarView === 'month') {
      return (
        <CalendarMonth
          checkOverflowAndResize={this.checkOverflowAndResize}
          events={eventsToDisplay}
          hasEvents={hasEvents}
          isError={isError}
          isPortrait={isPortrait}
          now={now}
          nowDateString={this.getDateString()}
          nowTimeString={this.getTimeString()}
          startDay={startDay}
        />
      );
    }
    if (calendarView === 'week') {
      return (
        <CalendarWeek
          checkOverflowAndResize={this.checkOverflowAndResize}
          events={eventsToDisplay}
          hasEvents={hasEvents}
          isError={isError}
          isPortrait={isPortrait}
          now={now}
          nowDateString={this.getDateString()}
          nowTimeString={this.getTimeString()}
          startDay={startDay}
        />
      );
    }
    if (calendarView === 'workweek') {
      return (
        <CalendarWorkWeek
          events={eventsToDisplay}
          hasEvents={hasEvents}
          isError={isError}
          isPortrait={isPortrait}
          now={now}
          nowDateString={this.getDateString()}
          nowTimeString={this.getTimeString()}
        />
      );
    }
    const todayMonth = now.getMonth();
    const todayDate = now.getDate();
    const eventsPresentToday = eventsToDisplay[todayMonth] && eventsToDisplay[todayMonth][todayDate] && eventsToDisplay[todayMonth][todayDate].events.length > 0;
    return (
      <CalendarDay
        events={eventsPresentToday ? eventsToDisplay[todayMonth][todayDate].events : []}
        isError={isError}
        isPortrait={isPortrait}
        now={now}
        nowDateString={this.getDateString()}
        nowTimeString={this.getTimeString()}
      />
    );
  }

  setNumEventsDisplayedPerDay() {
    const { calendarView, isPortrait } = this.props;
    if (calendarView === 'month') {
      this.numEventsDisplayedPerDay = 2;
    } else if (calendarView === 'day') {
      this.numEventsDisplayedPerDay = isPortrait ? 14 : 10;
    } else {
      this.numEventsDisplayedPerDay = isPortrait ? 8 : 9;
    }
  }

  // Normally, just sort events by ascending start time. If we are looking at events for today,
  // put all completed events at the start.
  sortFunctionGenerator(now: Date, isToday: boolean) {
    return ((event1: CalendarEvent, event2: CalendarEvent) => {
      const startTimestamp1 = new Date(event1.startDate).getTime();
      const startTimestamp2 = new Date(event2.startDate).getTime();
      if (isToday) {
        const endTimestamp1 = new Date(event1.endDate).getTime();
        const endTimestamp2 = new Date(event2.endDate).getTime();
        if (endTimestamp1 < now.getTime() && endTimestamp2 > now.getTime()) {
          return -1;
        } else if (endTimestamp1 > now.getTime() && endTimestamp2 < now.getTime()) {
          return 1;
        }
      }
      return startTimestamp1 - startTimestamp2;
    });
  }

  update() {
    const { events } = this.state;
    const newNow = new Date();
    const todayMonth = newNow.getMonth();
    const todayDate = newNow.getDate();
    this.setState({ now: newNow });
    if (events && events[todayMonth] && events[todayMonth][todayDate]) {
      const currTodayEvents = events[todayMonth][todayDate].slice().sort(this.sortFunctionGenerator(newNow, true));
      const updatedEvents = this.updateEventsToDisplay(currTodayEvents);
      this.setState(prevState => ({
        ...prevState,
        events: {
          ...prevState.events,
          [todayMonth]: {
            ...prevState.events[todayMonth],
            [todayDate]: currTodayEvents
          }
        },
        eventsToDisplay: {
          ...prevState.eventsToDisplay,
          [todayMonth]: {
            ...prevState.eventsToDisplay[todayMonth],
            [todayDate]: {
              events: updatedEvents,
              numHiddenEvents: Math.max(0, events[todayMonth][todayDate].length - this.numEventsDisplayedPerDay)
            }
          }
        }
      }));
    }
    this.updateTimeout = window.setTimeout(this.update.bind(this), this.getMsUntilNextMinute(newNow));
  }

  updateEventsToDisplay(todaysEvents: CalendarEvent[]) {
    const { now } = this.state;
    const eventsHappeningNowIndices: number[] = [];
    let firstEventToDisplayIndex: number = 0;

    for (let i = 0; i < todaysEvents.length; i++) {
      const isHappeningNow = todaysEvents[i].startDate.getTime() < now.getTime() && todaysEvents[i].endDate.getTime() > now.getTime();
      todaysEvents[i].happeningNow = isHappeningNow;
      if (isHappeningNow) {
        eventsHappeningNowIndices.push(i);
      }
    }

    if (eventsHappeningNowIndices.length === 0) {
      while (firstEventToDisplayIndex < todaysEvents.length) {
        if (todaysEvents[firstEventToDisplayIndex].startDate.getTime() < now.getTime()) {
          firstEventToDisplayIndex++;
        } else {
          break;
        }
      }
    } else {
      firstEventToDisplayIndex = eventsHappeningNowIndices[0];
    }
    const lastIndex = firstEventToDisplayIndex + this.numEventsDisplayedPerDay;
    if (lastIndex > todaysEvents.length) {
      firstEventToDisplayIndex = Math.max(0, todaysEvents.length - this.numEventsDisplayedPerDay);
    }
    return todaysEvents.slice(firstEventToDisplayIndex, firstEventToDisplayIndex + this.numEventsDisplayedPerDay);
  }

  // this is for fixing philips panels rendering bigger texts
  checkOverflowAndResize(element: HTMLDivElement | null) {
    if (!element) {
      return;
    }

    if (element.scrollWidth > element.clientWidth) {
      element.style.fontSize = '55px';
    }
  }
}
