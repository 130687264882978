import Clock from '@viviedu/react-clock';

import './style.scss';

interface Props {
  now: Date;
}

export default function ClockAnalog({ now }: Props) {
  return (
    <div className="ClockAnalog">
      <Clock
        hourHandWidth={5}
        minuteHandWidth={3}
        renderNumbers={true}
        secondHandLength={70}
        secondHandWidth={2}
        size={500}
        value={now}
      />
    </div>
  );
}
