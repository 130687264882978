import { Component, ReactNode } from 'react';

export type AddListener = typeof Socket.prototype.addListener;
export type RemoveSocketCallback = ReturnType<typeof Socket.prototype.addListener>;
export type SendEvent = typeof Socket.prototype.sendEvent;

interface OnSocketMessage {
  (data: Record<string, unknown>): void;
}

interface Props {
  children: (
    addSocketListener: AddListener,
    sendSocketEvent: SendEvent
  ) => ReactNode;
  serverPort: string;
}

const socketReconnectDelay = 5000;

export default class Socket extends Component<Props> {
  listeners = new Map<string, Set<OnSocketMessage>>();
  connection: {
    state: 'disconnected'
  } | {
    state: 'connecting',
    socket: WebSocket
  } | {
    state: 'connected',
    socket: WebSocket
  } = {
    state: 'disconnected'
  };

  constructor(props: Props) {
    super(props);

    this.connect = this.connect.bind(this);
  }

  componentDidMount() {
    this.connect();
  }

  addListener(message: string, callback: OnSocketMessage) {
    let listeners = this.listeners.get(message);
    if (!listeners) {
      listeners = new Set();
      this.listeners.set(message, listeners);
    }

    listeners.add(callback);

    return this.removeListener.bind(this, message, callback);
  }

  connect() {
    try {
      const socket = new WebSocket(`ws://localhost:${this.props.serverPort}`);
      this.connection = {
        state: 'connecting',
        socket
      };

      socket.addEventListener('close', ({ code, reason }) => {
        console.log('socket close', { code, reason });

        this.connection = {
          state: 'disconnected'
        };
        setTimeout(this.connect, socketReconnectDelay);
      });

      socket.addEventListener('error', () => console.warn('socket error'));

      socket.addEventListener('message', ({ data }) => {
        try {
          const { command, payload } = JSON.parse(data);

          console.log('socket message', {
            command,
            payload
          });

          this.listeners.get(command)?.forEach((listener) => listener(payload));
        } catch (error) {
          console.error('error while parsing socket message', {
            error
          });
        }
      });

      socket.addEventListener('open', () => {
        console.log('socket open');

        this.connection = {
          state: 'connected',
          socket
        };
      });
    } catch (error) {
      setTimeout(this.connect, socketReconnectDelay);
    }
  }

  removeListener(message: string, callback: OnSocketMessage) {
    this.listeners.get(message)?.delete(callback);
  }

  render() {
    return this.props.children(this.addListener.bind(this), this.sendEvent.bind(this));
  }

  sendEvent(event: string, data: Record<string, unknown> = {}) {
    console.log('sending socket message', {
      event,
      data
    });
    if (this.connection.state === 'connected') {
      return this.connection.socket.send(JSON.stringify({
        event,
        data
      }));
    }
  }
}
