import { Component, RefObject } from "react";
import React from "react";
import Stopwatch from "../Stopwatch";
import "./style.scss";
import { AddListener } from "../Socket";

interface Props {
  isPortrait: boolean;
  onAddSocketListener: AddListener;
}

interface State {
  indexToProcess: number
}

const videos = [
  {
    quality_str: "local_video_5555_media.mp4",
    url: "https://192-168-1-111.vivi-box.io:5555/media.mp4"
  },
  {
      quality_str: "fullhd_portrait_mode",
      url: "https://www.visitnsw.com/sites/visitnsw/files/2021-10/output_mobile.mp4"
  },
  // {
  //   quality_str: "youtube_256x144_30fps",
  //   url: "https://rr3---sn-hufvjvgx-hxae.googlevideo.com/videoplayback?expire=1709714283&ei=C9fnZdbMEZKs9fwPrJCuiAU&ip=103.31.112.166&id=o-ALbrABsep3sfD9KkirbSnx4Vw2-WNCRz6Do5YNlbD5Sv&itag=160&source=youtube&requiressl=yes&xpc=EgVo2aDSNQ%3D%3D&mh=NH&mm=31%2C29&mn=sn-hufvjvgx-hxae%2Csn-ntq7yned&ms=au%2Crdu&mv=m&mvi=3&pl=22&initcwndbps=3817500&vprv=1&svpuc=1&mime=video%2Fmp4&gir=yes&clen=2823440&dur=313.780&lmt=1708695063044579&mt=1709691941&fvip=3&keepalive=yes&fexp=24007246&c=IOS&txp=4502434&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cxpc%2Cvprv%2Csvpuc%2Cmime%2Cgir%2Cclen%2Cdur%2Clmt&sig=AJfQdSswRQIhAOfgHflPsexre4wbnq1Tdq4FFhyhnOI3xW8UPWEE2XjDAiBi1xOfeKt2k_IsSUjwGyYvHqbBMKzC-Zz9PAaSLv8n9g%3D%3D&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=APTiJQcwRAIgPATwQr5bdwHHxmf1ymw_O8YcsfZkhADtzLOmhCCGjAUCIFZgurd3zTzh0q-xTdI2wxsN5KVeKw59r6ZnHhsm6hg_"
  // },
  // {
  //   quality_str: "youtube_426x240_30fps",
  //   url: "https://rr3---sn-ntq7yned.googlevideo.com/videoplayback?expire=1709714405&ei=hdfnZbGSNJmo9fwP8tKooA4&ip=103.31.112.166&id=o-AMA8H2n2uUVsf4meuKOtplaJoEV6kWhJEVshNAyKPE7S&itag=395&source=youtube&requiressl=yes&xpc=EgVo2aDSNQ%3D%3D&mh=NH&mm=31%2C26&mn=sn-ntq7yned%2Csn-hxa7zn7z&ms=au%2Conr&mv=m&mvi=3&pl=22&initcwndbps=950000&spc=UWF9f1ejUobEsiGKSGnUnkh3RbZm0H2fOqT1Jsbpnn-cyNo&vprv=1&svpuc=1&mime=video%2Fmp4&gir=yes&clen=4355021&dur=313.780&lmt=1625696047181468&mt=1709692629&fvip=1&keepalive=yes&fexp=24007246&beids=24350319&c=ANDROID&txp=4531232&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cxpc%2Cspc%2Cvprv%2Csvpuc%2Cmime%2Cgir%2Cclen%2Cdur%2Clmt&sig=AJfQdSswRQIhANKLiBxjmiXXM4TL44uDykPtLVTzQh0udoxCfnxdxxTBAiBb7uj189Ptq9bL7sVjvrijntxWprtqX92q5oe6uNcf6Q%3D%3D&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=APTiJQcwRQIgTxgaMGykz2nadwPan1GpYUlyr-ULkrba65QLxkeOBnUCIQD6_meOXjxi5bWdygDNtDFA4sD9cPJGTc1b9Mpg-7Y_fg%3D%3D"
  // },
  // {
  //   quality_str: "youtube_640x360_30fps",
  //   url: "https://rr3---sn-ntq7yned.googlevideo.com/videoplayback?expire=1709714488&ei=2NfnZaHiFOOs9fwP-aSDkAo&ip=103.31.112.166&id=o-AK3h3a9s037yjHD2AplDT1lZY0RXc84MjtkVrAVJqKLw&itag=134&source=youtube&requiressl=yes&xpc=EgVo2aDSNQ%3D%3D&mh=NH&mm=31%2C29&mn=sn-ntq7yned%2Csn-ntqe6n7r&ms=au%2Crdu&mv=m&mvi=3&pl=22&initcwndbps=950000&vprv=1&svpuc=1&mime=video%2Fmp4&gir=yes&clen=13489194&dur=313.780&lmt=1708695017272827&mt=1709692629&fvip=2&keepalive=yes&fexp=24007246&c=IOS&txp=4502434&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cxpc%2Cvprv%2Csvpuc%2Cmime%2Cgir%2Cclen%2Cdur%2Clmt&sig=AJfQdSswRQIgZktcvOe5zeTQeGbEaRsYNPjZPPkwHUh8SZB9NBmEpNwCIQCh8qttBkOwahPdE-f0Jg-2Ps946DdBc2d9WA8OjaAhiQ%3D%3D&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=APTiJQcwRQIhAMvArFxMp9MZg8KDJxlKX0iCvkGJpSv-INtNdva31RSfAiBb0LW9o8rOTRWE9Ly9k7_G05VT66NFTuwOa2y8FHu2Vw%3D%3D"
  // },
  // {
  //   quality_str: "youtube_1280x720_30fps",
  //   url: "https://rr3---sn-ntq7yned.googlevideo.com/videoplayback?expire=1709714647&ei=d9jnZbGqCsW_juMPgf2nmAY&ip=103.31.112.166&id=o-AD4MNgFhyeuti3SLQtt-2q90eUskHFUABVn3rnzh78Zj&itag=136&aitags=133%2C134%2C135%2C136%2C160%2C242%2C243%2C244%2C247%2C278%2C298%2C299%2C302%2C303%2C308%2C315%2C330%2C331%2C332%2C333%2C334%2C335%2C336%2C337%2C394%2C395%2C396%2C397%2C398%2C399%2C400%2C401%2C694%2C695%2C696%2C697%2C698%2C699%2C700%2C701&source=youtube&requiressl=yes&xpc=EgVo2aDSNQ%3D%3D&mh=NH&mm=31%2C29&mn=sn-ntq7yned%2Csn-ntqe6n7r&ms=au%2Crdu&mv=m&mvi=3&pl=22&initcwndbps=950000&spc=UWF9f-zVM8NAIr6-6Wh830wnu9_PAS9woN336JCKrF3nPP8&vprv=1&svpuc=1&mime=video%2Fmp4&ns=RzMxKu5T-jvQqRvc0luC6gQQ&gir=yes&clen=62691816&dur=313.780&lmt=1708691750580624&mt=1709692629&fvip=2&keepalive=yes&fexp=24007246&c=WEB&sefc=1&txp=4502434&n=94W1YxE4ck6CjQ&sparams=expire%2Cei%2Cip%2Cid%2Caitags%2Csource%2Crequiressl%2Cxpc%2Cspc%2Cvprv%2Csvpuc%2Cmime%2Cns%2Cgir%2Cclen%2Cdur%2Clmt&sig=AJfQdSswRQIgZQZcbANY-vLr_atXO8eX8Ek7CpKQzMVXlTMi0AuZKAkCIQDLeVn42CW0vLBBKGFkhyfAoN5BpTXEzHr-tiRNLJ-nLw%3D%3D&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=APTiJQcwRgIhAMrBDLwi9xzKK0kYEbSCG0cf4DRmk_V8SAg1GZQVWIVEAiEA4a5DEqsXw1a1PejKs_zg9OuGIFuMXtnqhfE0ztcdbOs%3D"
  // },
  // {
  //   quality_str: "customer_cgs_1920x1080_25fps",
  //   url: "https://media.digistormhosting.com.au/cgs-au-vic-256-website/video/CGS210601-highlights_v3.mp4"
  // }

]

  

export default class Multizone extends Component<Props, State> {

  video: RefObject<HTMLVideoElement> | null;

  constructor(props: Props) {
    super(props);
    const numVideos = videos.length;
    this.state = {
      indexToProcess: Math.max(0, Math.floor(Math.random() * numVideos))
    }
    this.video = React.createRef();
  }

  componentDidMount(): void {
    // console.log("Force play");
    // this.video?.current?.play();    
  }

  render() {
    const { isPortrait, onAddSocketListener } = this.props;
    console.log(this.state)
    const { indexToProcess } = this.state;
    const {quality_str, url} = videos[indexToProcess];
    return (
      <div className="Multizone">
        <div className="sidebar">
          <Stopwatch
            isPortrait={isPortrait}
            onAddSocketListener={onAddSocketListener}
          />
          <Stopwatch
            isPortrait={isPortrait}
            onAddSocketListener={onAddSocketListener}
          />
        </div>
        <div className="main-content">
          <h1> {quality_str} </h1>
          <video
            ref={this.video}
            title="test"
            src={url}
            // playsinline={true}
            autoPlay={true}
            playsInline={true}
            controls={false}
            muted={true}
          ></video>
        </div>
      </div>
    );
  }
}
