import { Component } from 'react';

import './style.scss';

export type Size = 'small' | 'medium' | 'large';

interface Props {
  size?: Size;
  value: string;
}

interface State {
  measuringWidth: boolean;
  width: number | null;
}

export default class SevenSegmentDigit extends Component<Props, State> {
  get normaliseClass() {
    const { value } = this.props;
    return /^\d|-| $/.test(value)
      ? 'normaliseWidth'
      : value === ':'
        ? 'normaliseHeight'
        : '';
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      measuringWidth: this.normaliseClass === 'normaliseWidth',
      width: null
    };
  }

  measureWidth(div: HTMLDivElement | null) {
    if (!div || !this.state.measuringWidth) {
      return;
    }

    this.setState({
      measuringWidth: false,
      width: div.offsetWidth
    });
  }

  render() {
    const { size, value } = this.props;
    const { measuringWidth, width } = this.state;
    return (
      <>
        <div className={`SevenSegmentDigit ${this.normaliseClass} ${size ?? 'large'}`} ref={(div) => this.measureWidth(div)} style={{ ...width && { width } }}>
          {measuringWidth ? '0' : value}
        </div>
      </>
    );
  }
}
